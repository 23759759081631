import {createApp} from 'vue'
import {createPinia, Pinia} from 'pinia'
import {App} from "@vue/runtime-core";
import AppVue from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'

import axiosConfig from "./plugins/axiosConfig";

import common from "./plugins/common";
import Table from "@/components/Table.vue"
import * as ElementPlusIconsVue from '@element-plus/icons-vue'

/// element start
import installElementPlus from "@/plugins/element"
/// element end

const app:App<Element> = createApp(AppVue)

installElementPlus(app)

axiosConfig(app)

common(app)

for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
    app.component(key, component)
}

app.component("Table", Table);

const pinia: Pinia = createPinia();
app.use(pinia);
app.use(store).use(router).mount('#app')


